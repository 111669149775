import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions } from "./Actions";
import { Mutations } from "./Mutations";
import store from "@/store"
import PaginationRequest from "@/models/general/PaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import PaymentsService from "@/core/services/payments/PaymentServices";
import PaymentState from "../../models/PaymentState";
import GetAllPaymentsResponse from "@/models/payments/GetAllPaymentsResponse";


/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
const service =  new PaymentsService();
/**
 * Creamos el store para la sección de pagos
 */
@Module({ dynamic: true, store, namespaced: true, name: "Payments"})
export default class CashRegisterModule extends VuexModule implements PaymentState {
    table: PaginationResponse<GetAllPaymentsResponse[]> = {
        currentPage: 1,
        totalPages: 0,
        totalRecords: 0,
        data: []
    };

    loading = false;

    get getPaymentsOptions(): PaginationResponse<GetAllPaymentsResponse[]> {
        return this.table;
    }

    @Mutation
    [Mutations.SET_TABLE](value: PaginationResponse<GetAllPaymentsResponse[]>){
        this.table = value;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Action({ commit: Mutations.SET_TABLE })
    async [Actions.GET_TABLE](paginator: PaginationRequest){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getTable(paginator)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }
}