import { AxiosResponse } from 'axios';
import SwalMessageService from '@/core/services/SwalMessageService';
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import { translate } from '@/core/plugins/i18n';
import GetAllPaymentsResponse from '@/models/payments/GetAllPaymentsResponse';
import PaginationResponse from '@/models/general/PaginationResponse';
import PaginationRequest from '@/models/general/PaginationRequest';

/**
 * Servicio de la seccion Pagos
 */
export default class PaymentsService implements GeneralService {
    apiController = "/v1";
    messageService: MessageService = new SwalMessageService();
    
    /**
     * Busca los datos de la tabla de Pagos
     * @param paginator datos de paginación para consultar
     * @returns datos de paginado con los datos de la tabla
     */
    async getTable(paginator: PaginationRequest): Promise<AxiosResponse<PaginationResponse<GetAllPaymentsResponse[]>>>{
        return ApiService.query<PaginationResponse<GetAllPaymentsResponse[]>>(this.apiController + "/c533ed6a-a588-4371-aaa9-494baf51ded8"
        , {
            params: {}
        }
        )
        .then(resp => {
            return resp;
        }).catch(error => {
            this.messageService.error(translate("MESSAGES.GET_INFO_ERROR"));
            console.log(error);
            return { data: null} as AxiosResponse;
        });
    }
}