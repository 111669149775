
import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import FilterPayments from "./components/FilterPayment.vue";
import PDFViewer from "../cashregister/components/PDFViewer.vue";
import { getModule } from 'vuex-module-decorators'
import PaymentModule from "@/store/modules/Payments/modules/payments-store";
import PaginationRequest from "@/models/general/PaginationRequest";
import { formatToDatatable } from "../../common/formaterToDatatable";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import Paginator from "@/components/c-paginator/Paginator.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";

export default defineComponent({
  components: {
     FilterPayments,
     Datatable,
     Paginator,
     PDFViewer,
     Datarow,
  },
  setup() {
    const module = getModule(PaymentModule);
    const openForm = ref(HTMLButtonElement);
    let records = ref<Array<any>>([]);
    const checkedCustomers = ref([]);

    /**
     * CONFIGURACIONES DE LA TABLA
     */
     const configTable = reactive([
          { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
          { PropName: "numberPay", HeadLabel: "# Pago", Type: "text", VisibleInGrid: true },
          { PropName: "supplierName", HeadLabel: "Nombre del Proveedor", Type: "text", VisibleInGrid: true },
          { PropName: "order", HeadLabel: "Orden Compra", Type: "text", VisibleInGrid: true },
          { PropName: "amount", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },            
          { PropName: "date", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true },            
          { PropName: "status", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },            
      ]);

    /**Obtención de información*/
    var request: PaginationRequest = {
        currentPage: 1,
        pageSize: 15
    };

    module.GET_TABLE(request);

    /** FUNCIONES */
    const handleChangePage = (page: number) => {
      console.log("proximamente...");
    }

    const handleCrudAction = () => {
        
        Swal.fire({
        title: '¿Está seguro de aplicar el pago?',
        text: "Se procederá a aplicar el pago para esta solicitud y no podrá revertir el pago",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6f1688',
        cancelButtonColor: '#7E829',
        confirmButtonText: 'Si, pagar'
      }).then((result) => {
        if (result.isConfirmed) {
          
          Swal.fire(
            'Se realizó el pago',
            'Se aplicó su pago correctamente',
            'success'
          ).then((result) => {
            checkedCustomers.value = [];
              if(openForm.value) {
            (openForm.value as unknown as HTMLButtonElement).click();
          }
          })
        }
      });
        
    };

    onMounted(() => {
       setCurrentPageBreadcrumbs(translate('BREADCRUMB.PAYMENT'), [translate('BREADCRUMB.MODULES')]);
    });

    return {
      checkedCustomers,
      openForm,
      records,
      configTable,
      headers: configTable.filter(h => h.VisibleInGrid).map(h => h.HeadLabel),

      renderRows: computed(() => formatToDatatable(module.getPaymentsOptions.data, configTable)),

      handleCrudAction,
      handleChangePage
    }

  }
})
